.notification {
  background-color: #f8d7da; /* Example background color */
  padding: 15px;
  border: 1px solid #f5c6cb; /* Example border color */
  border-radius: 5px;
  margin-bottom: 20px; /* Adds space between notifications */
  display: flex;
  justify-content: space-between; /* Positions close button to the right */
  align-items: center; /* Centers content vertically */
}

.close-btn {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #721c24; /* Example close button color */
}
