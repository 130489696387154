.board_tree {
  overflow: auto;
  display: flex;
  justify-content: center;
  padding: 20px 0;
  background-image: url("../../../public/images/boardbg.png");
  background-size: cover;
  height: 56vh;
  position: relative;
  background-position: top;
  /* background-position: center center; */
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.board_tree ul {
  position: relative;
  padding: 1em 0;
  white-space: nowrap;
  margin: 0 auto;
  text-align: center;
}

.board_tree>ul {
  top: 200px;
  overflow: auto;
  width: 100%;
}

.board_tree ul::after {
  content: "";
  display: table;
  clear: both;
}

.board_tree li {
  display: inline-block;
  vertical-align: top;
  text-align: center;
  list-style-type: none;
  position: relative;
  /* padding: 0 0 0 0; */
  /* margin: 0 20px; */
  padding: 0 20px;
  height: 200px;
}

.center_Content_section {
  display: flex;
  flex-direction: column;
}

.tree_view_parent_box {
  padding-top: 0;
  padding-bottom: 100px;
  padding-left: 50px;
  padding-right: 50px;
  background-color: #f2f2f2;
}

.board_shieds_container,
.blessed_board {
  display: flex;
  align-items: center;
}

.board_shieds_container {
  justify-content: space-evenly;
  width: 100%;
}

.board_shieds_last-leg {
  display: flex;
  flex-direction: column;
  gap: 60px;
}

.board_shieds {
  cursor: pointer;
}

.alpaha_board .board_shieds.right-leg img,
.alpaha_board .board_shieds.left-leg img {
  width: 90%;
}
.reflect{
  -webkit-box-reflect: right;
}
.board_shieds {
  max-width: 140px;
}

.min-width_last-leg,
.tree_view_headings .span-title,
.alpaha_board-min-width {
  min-width: 140px;
}

.board_shieds img {
  width: 100%;
}
.boardarrays-grid{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 10px;
}
.boardarrays-grid-child{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.board_shieds {
  position: relative;
  text-align: center;
}

.board_shieds h6 {
  position: absolute;
  top: 38%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}

/* .board_tree li::before,
                    .board_tree li::after {
                        content: '';
                        position: absolute;
                        top: 0;
                        right: 50%;
                        border-top: 2px solid #ccc;
                        width: 50%;
                        height: 1em;
                    } */

/* .board_tree li::after {
                        right: auto;
                        left: 50%;
                        border-left: 2px solid #ccc;
                    } */

.board_tree li:only-child::after,
.board_tree li:only-child::before {
  display: none;
}

.board_tree li:only-child {
  padding-top: 0;
}

/* .board_tree li:first-child::before,
        .board_tree li:last-child::after {
            border-right: 2px solid #ccc;
        } */

/* .board_tree li:last-child::before {
                        border-right: 2px solid #ccc;
                        border-radius: 0 5px 0 0;
                    } */

.board_tree li:first-child::after {
  border-radius: 5px 0 0 0;
}

/* .board_tree ul ul::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 50%;
                        border-left: 2px solid #ccc;
                        width: 0;
                        height: 1em;
                    } */
.nodata_image_tree img {
  max-width: 180px;
}
.nodata_image_tree{
  max-height: 214px;
  align-self: center;
}
.nodata_image_tree h6 {
  text-align: center;
  font-family: 'Inter';
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
}
.board_tree li a {
  /* border: 2px solid #ccc; */
  padding: 0.5em 0.75em;
  text-decoration: none;
  color: #666;
  font-family: Arial, sans-serif;
  font-size: 0.85em;
  display: inline-block;
  border-radius: 5px;
  transition: all 0.5s;
  width: fit-content;
  z-index: 99;
}

.board_tree li a:hover,
.board_tree li a:hover+ul li a img {
  /* filter: drop-shadow(0px 0px 6px #94a0b4); */
}

.board_tree li a:hover+ul li::after,
.board_tree li a:hover+ul li::before,
.board_tree li a:hover+ul::before,
.board_tree li a:hover+ul ul::before {
  border-color: #94a0b4;
}

.leader>ul>.left_child {
  /* background-color: yellow !important; */
  transform: rotate(90deg) !important;
  margin-right: 200px;
}

.leader>ul>.right_child {
  /* background-color: green !important; */
  transform: rotate(-90deg) !important;
  margin-left: 200px;
}

.leader>a {
  /* background-color: blue; */
  /* margin: 0px 40px 0px 40px ; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* display: flex !important;
                        justify-content: center;
                        align-items: center; */
}

.leader>ul>.right_child a {
  transform: rotate(90deg) !important;
  margin-top: 1em;
}

.leader>ul>.left_child a {
  transform: rotate(-90deg) !important;
  margin-top: 1em;
}

.leader>ul>.left_child .down {
  transform: rotate(90deg) !important;
  margin-top: 10px;
  margin-left: 15px;
}

.leader>ul>.right_child .down {
  transform: rotate(-90deg) !important;
  margin-top: 10px;
  margin-right: 15px;
}

.book-open {
  opacity: 0;
  transform: scaleY(0);
  transform-origin: top;
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.book-open.visible {
  opacity: 1;
  transform: scaleY(1);
}

/* =====left branch==== */

.left_child li.left_child::before {
  outline: solid 1px #dedede;
  width: 50%;
  content: "";
  right: 0;
  position: absolute;
  top: 0;
}

.left_child li.right_child::before {
  outline: solid 1px #dedede;
  width: 50%;
  content: "";
  left: 0;
  position: absolute;
  top: 0;
}

.left_child li::after {
  outline: solid 1px #dedede;
  content: "";
  left: 50%;
  position: absolute;
  top: 1px;
  height: 10px;
}

.left_child ul::after {
  outline: solid 1px #dedede;
  content: "";
  left: 50%;
  position: absolute;
  top: 1px;
  height: 13px;
}

.board_tree li a:hover~ul::after,
.board_tree li a:hover~ul ul::after,
.board_tree li a:hover~ul li::after,
.board_tree li a:hover~ul li::before {
  outline: solid 1px #fbba00 !important;
}

/* =====right branch==== */

.right_child li.left_child::before {
  outline: solid 1px #dedede;
  width: 50%;
  content: "";
  right: 0;
  position: absolute;
  top: 0;
}

.right_child li.right_child::before {
  outline: solid 1px #dedede;
  width: 50%;
  content: "";
  left: 0;
  position: absolute;
  top: 0;
}

.right_child li::after {
  outline: solid 1px #dedede;
  content: "";
  left: 50%;
  position: absolute;
  top: 1px;
  height: 10px;
}

.right_child ul::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 1px;
  height: 13px;
  outline: solid 1px #dedede;
}

li.leader>ul>.left_child::after {
  content: "";
  position: absolute;
  left: 50%;
  top: -80px;
  height: 91px;
  outline: solid 1px #dedede;
}

li.leader>ul>.right_child::after {
  content: "";
  position: absolute;
  right: 50%;
  top: -80px;
  height: 91px;
  outline: solid 1px #dedede;
}

.centered-loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  /* Ensure it appears above other content */
  background-color: rgba(255,
      255,
      255,
      0.8);
  /* Optional: semi-transparent background */
  padding: 20px;
  /* Optional: add some padding around the loader */
  border-radius: 8px;
  /* Optional: rounded corners */
}

.slider-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.slider {
  -webkit-appearance: none;
  width: 200px;
  height: 8px;
  background: #ddd;
  border-radius: 5px;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

span {
  margin-left: 10px;
}

.navigation-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.nav-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  margin: 0 10px;
}

.nav-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.nav-button:hover:not(:disabled) {
  background-color: #f0f0f0;
  border-radius: 50%;
}

.span-title {
  font-size: larger;
  font-weight: bold;
  margin: 0;
}

.board_view_bottom {
  width: 260px;
  height: 90px;
  position: absolute;
  bottom: 0
}

@media (max-width: 1200px) {
  .board_shieds {
    max-width: 112px;
  }

  .min-width_last-leg,
  .tree_view_headings .span-title,
  .alpaha_board-min-width {
    min-width: 112px;
  }

  .tree_view_headings .span-title {
    font-size: 24px !important;
  }
}

@media (max-width: 992px) {
  .board_shieds {
    max-width: 95px;
  }

  .min-width_last-leg,
  .tree_view_headings .span-title,
  .alpaha_board-min-width {
    min-width: 95px;
  }

  .tree_view_headings .span-title {
    font-size: 20px !important;
  }

  .board_shieds_last-leg {
    gap: 40px;
  }
}

@media (max-width: 768px) {
  .board_shieds_container {
    flex-direction: column !important;
    gap: 19px;
  }

  .board_shieds_last-leg {
    gap: 40px;
    flex-direction: row !important;
  }

  .board_shieds {
    max-width: 100px;
  }

  .min-width_last-leg,
  .tree_view_headings .span-title,
  .alpaha_board-min-width {
    min-width: 100px;
  }

  .tree_view_headings .span-title {
    font-size: 20px !important;
  }

  .board_tree {
    flex-direction: column;
    height: inherit !important;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    min-height: 75dvh !important;
  }

  .tree_view_parent_box {
    padding-bottom: 9px;
  }

  .board_view_bottom {
    width: 260px;
    height: 90px;
    position: inherit !important;
  }

  .tree_view_headings {
    display: none !important;
  }
}

@media (max-width: 768px) {
  .tree_view_parent_box {
    padding: 0 3px !important;
  }

  .main_dv .center_Content_section {
    width: 100% !important;
  }
}
@media (max-width: 576px) {
  .joining_Teammbr_section > .row {
    row-gap: 0px;
}
}